.pdf-cart {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 45px;
}

.custom-card {
  position: relative;
  width: 430px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: solid 2px black;
}

.custom-card-body {
  padding: 20px 10px 10px;
  text-align: center;
}

.logo-container {
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 100px;
}

.logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.data {
  margin-top: 10px;
}

.data-item {
  margin-bottom: 5px;
  font-size: 1.2em;
  color: #333;
  text-align: left;
}
.print-button {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.signature-box {
  border-top: 1px solid #000;
  margin-top: 20px;
  padding-top: 10px;
  height: 100px; /* Adjust height as needed */
}
