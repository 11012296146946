/* btncustomize.module.css */
.custom-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 60%;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-right: 0px;
  padding-left: 0px;
}

.custom-btn {

  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.custom-btn:hover {
  color: black; /* Change background color on hover */
}
