.loading-bar {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-spinner {
  width: 150px; /* Increase the size */
  height: 150px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
