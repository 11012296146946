/* CSS */
.button {
  background-color: #f4f0e6!important;
  align-items: center;
  color: black !important;
  border: none !important;
  box-shadow: none;
  padding: 2px 10px !important;
  margin-right: 10px !important;
  border-radius: 5px !important;
  margin-left:10px ;
  cursor: pointer !important;
   transition: background-color 0.3s!important;
     

}

.button:hover {
  background-color: #e2c636 !important; /* Hover background color */
  color: white !important; /* Text color on hover */
}

.icon {
  color: inherit !important;
    margin-right: 10px !important;
 /* Set icon color */
}
.buttonShdow{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
