

.text-table-item {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  border-bottom: 1px solid #ddd;
  font-size: 1em;
}

.text-table {
  padding: 10px;
  border-radius: 5px;
  width: 350px;
}

/* style.module.css */
.text-table-title {
  text-align: center;
  font-family: 'Arial, sans-serif'; /* Change this to your preferred font-family */
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 10px;
}
