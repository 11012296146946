h6 {
  font-size: 1em;
}

.invoice {
  background: #fff;
  width: 100%;
  padding: 50px;
}

.logo {
  width: 3cm;
}

.document-type {
  text-align: right;
  color: #444;
}

.conditions {
  font-size: 0.7em;
  color: #666;
}

.bottom-page {
  font-size: 0.7em;
}

.space {
  margin-right: 100px;
}
@media print {
  .container {
    width: auto;
    margin: 0;
    padding: 0;
    font-size: 10pt;
  }
}

.print-btn {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 20px;
}


.totalItem {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  border-bottom: 1px solid #ddd;
  font-size: 1em;
}
