.input-style {
    padding: 10px;
    border: 2px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    color: #555;
    outline: none;
  }
  
  .input-style:focus {
    border-color: #fbdc10;
    box-shadow: 0 0 0 0.2rem #fbdc10;
  }
  
  .input-v2 {
    transition: none;
    border: 1px solid #ccc;
  
  
    &:focus-within {
      outline: 2px solid orange;
      border-color: #fbdc10;
      /* box-shadow: 0 0 0 0.2rem #fbdc10; */
    }
  }
  
  
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  
  ::-webkit-scrollbar {
    right: 0;
    width: 5px;
    height: 5px;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #fbf5f5c7;
    border-radius: 30%;
    width: 1px;
    height: 8px;
  }
  
  
  .p-datatable table {
    border-collapse: collapse;
  }
  
  .p-datatable th,
  .p-datatable td {
    border: 1px solid #ddd;
  }
  
  
  .leaflet-container {
    height: 900px !important;  /* Force height */
    width: 100% !important;    /* Ensure full width */
    min-height: 500px;     
  }
  
  .leaflet-bottom{
    opacity: 0;
  }
  
  

.p-accordion-toggle-icon{
  color: black;
}

@layer mantine, mantine-datatable;
