.card {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.table th,
.table td {
  padding: 8px;
  text-align: left;
  width: 300px;
}